import React from "react";
import "./Home.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
import HomeGallery from "../HomeGallery/HomeGallery";
import { BsArrowDown } from "react-icons/bs";
import "animate.css";
//Assets
import HomeBg from "../Assets/home-video.mp4";
import Amenity1 from "../Assets/Home/Amenity-1.jpg";
import Amenity2 from "../Assets/Home/Amenity-spa.png";
import Amenity3 from "../Assets/Home/Amenity-3.png";
import Amenity4 from "../Assets/Home/Amenity-4.png";
import Amenity5 from "../Assets/Home/Amenity-5.png";
import SecImg from "../Assets/Home/img-sec-2.jpg";
import Suite1 from "../Assets/Home/suite-1.png";
import Suite2 from "../Assets/Home/suite-2.png";
import DirtKart from "../Assets/Home/Dirt-Kart.jpg";
import WallClimbing from "../Assets/Home/wall-climbing.jpg";
import PaintBall from "../Assets/Home/Paintball.jpg";
import Zipline from "../Assets/Home/zipline.png";
import HRC from "../Assets/Home/high-rope-course.jpg";



const Home = () => {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   videoRef.current.play();
  // }, []);

  return (
    <div className="main-container">
      <Header />
      <FAB />

      <div className="hero-section">
        <section className="home-banner">
          <div className="bg-img">
            <video
              className="home-video-bg"
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
            >
              <source src="https://sportico.blr1.cdn.digitaloceanspaces.com/Website/hero_video.mp4" type="video/mp4" />
            </video>
            {/* <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Home/home_bg.png" alt="home background" /> */}

          </div>

          <div
            className="home-tag-line"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <p>Escape</p>
            <p>Explore</p>
            <p>Experience</p>
          </div>
          {/* <BsArrowDown className="home-bg-icon animate__animated animate__bounce animate__infinite	infinite" /> */}
        </section>

        {/* <section className="main-offers">
          <h1 className="">Latest Offers and Packages</h1>
          <div className="offers-conatiner">
            <div className="package">
              <img src={day_out} alt="home background" />
              <h6>DAY OUT</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access, Common indoor games, Fitness centre
                and access to all resort facilities
              </p>
            </div>
             <div className="package">
              <img src={corporate} alt="home background" />
              <h6>Corporate Team - from RS 1299</h6>
              <p>
                <span>Included</span> - Welcome drink, Lunch, Team building
                activities, Common games, Indoor games, Fitness center, Hi tea
              </p>
            </div> 
            <div className="package">
              <img src={day_night} alt="home background" />
              <h6>Superior Stay</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access,Indoor & Outdoor Games, Fitness
                centre and access to all resort facilities
              </p>
            </div>
            <div className="package">
              <img src={weekend} alt="home background" />
              <h6>Suite Stay</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access,Indoor & Outdoor Games, Fitness
                centre and access to all resort facilities
              </p>
            </div>
          </div>
        </section> */}
      </div>

      {/* Section About */}

      <div className="section-about">
        <section className="content-container">
          <h1 className="">
            Elevate Your Experience: Where Elegance Meets Adventure
          </h1>
          <p className="">
            Welcome to Sportico Bangalore Resort, where we redefine the concept
            of luxury and leisure. Imagine a place where every detail is crafted
            to meet your highest expectations. From our opulent wedding and
            corporate venues to adrenaline-pumping adventure sports, we offer a
            comprehensive experience that caters to all your needs. This isn't
            just a stay, it's an experience you'll cherish forever.
          </p>
          <p className="">
            Surrounded by scenic beauty and attractive tourist attractions we
            make it convenient for you to visit all the beautiful places with
            our inhouse guide. We can also help you arrange transport facilities
            for an easier commute to nearby places.
          </p>
        </section>
        <section
          className="img-conatiner"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={SecImg} alt="Pool View of the Resort" />
        </section>
      </div>

      {/* Section Sports */}

      <div className="section-sports">
        <div className='adv-title'>
          <h1 className="">Adventure Sports</h1>
          {/* <span>Opening soon</span> */}
        </div>
        <section>
          <div className="sport" data-aos="fade-up" data-aos-duration="1000">
            <img src={WallClimbing} alt="Sapphire Suite" />
            <h6>Wall Climbing</h6>
          </div>
          <div
            className="sport"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <img src={DirtKart} alt="Sapphire Suite" />
            <h6>Dirt Kart</h6>
          </div>
          <div
            className="sport"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <img src={Zipline} alt="Sapphire Suite" />
            <h6>Zipline</h6>
          </div>
          <div
            className="sport"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <img src={HRC} alt="Sapphire Suite" />
            <h6>High Rope Course</h6>
          </div>
          <div
            className="sport"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <img src={PaintBall} alt="Sapphire Suite" />
            <h6>Paintball</h6>
          </div>
        </section>
        <a href="/adventure" className="section-cta">
          View more
        </a>
      </div>
      {/* Section Rooms */}

      <div className="section-rooms">
        <section className="content-container">
          <h1 className="">Premium Suites</h1>
        </section>
        <section className="img-conatiner">
          <a
            href="/rooms"
            className="suite"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={Suite1} alt="Sapphire Suite" />
            <div className="suite-content">
              <h6>Superior</h6>
              <p>Cozy yet luxurious, perfect for a quick getaway</p>
            </div>
          </a>
          <a
            href="/rooms"
            className="suite"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <img src={Suite2} alt="Aqua Suite" />
            <div className="suite-content">
              <h6>Suite</h6>
              <p>Unwind in our suite. Ultimate comfort and elegance await. </p>
            </div>
          </a>
        </section>
      </div>

      {/* Section Amenities */}
      <div className="section-amenities">
        <div className="amenities-container">
          <section className="left-amenities-grp">
            <div
              className="amenity"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={Amenity1} alt="Sapphire Suite" />
              <p>Infinity Pool</p>
            </div>
            <div
              className="amenity"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img src={Amenity2} alt="Sapphire Suite" />
              <p>SPA - Cynara</p>
            </div>
            <div
              className="amenity"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img src={Amenity3} alt="Sapphire Suite" />
              <p>Mist Rooftop Bar</p>
            </div>
            <div
              className="amenity"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img src={Amenity4} alt="Sapphire Suite" />
              <p>Saffron restaurant</p>
            </div>
          </section>
          <section
            className=""
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <h1 className="">Experiences</h1>
            <div className="amenity amenity-5">
              <img src={Amenity5} alt="Sapphire Suite" />
              <p>Frequency pub</p>
            </div>
          </section>
        </div>
        <a href="/amenities" className="section-cta">
          Know more
        </a>
      </div>

      <HomeGallery />

      <Footer />
    </div>
  );
};
export default Home;
